exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-appointment-successfully-arranged-js": () => import("./../../../src/pages/appointment-successfully-arranged.js" /* webpackChunkName: "component---src-pages-appointment-successfully-arranged-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gtc-js": () => import("./../../../src/pages/gtc.js" /* webpackChunkName: "component---src-pages-gtc-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrie-clothing-and-textiles-js": () => import("./../../../src/pages/industrie/clothing_and_textiles.js" /* webpackChunkName: "component---src-pages-industrie-clothing-and-textiles-js" */),
  "component---src-pages-industrie-construction-industry-js": () => import("./../../../src/pages/industrie/construction_industry.js" /* webpackChunkName: "component---src-pages-industrie-construction-industry-js" */),
  "component---src-pages-industrie-glass-products-private-label-js": () => import("./../../../src/pages/industrie/glass_products_private_label.js" /* webpackChunkName: "component---src-pages-industrie-glass-products-private-label-js" */),
  "component---src-pages-industrie-individual-components-js": () => import("./../../../src/pages/industrie/individual_components.js" /* webpackChunkName: "component---src-pages-industrie-individual-components-js" */),
  "component---src-pages-industrie-sanitary-products-wholesale-js": () => import("./../../../src/pages/industrie/sanitary_products_wholesale.js" /* webpackChunkName: "component---src-pages-industrie-sanitary-products-wholesale-js" */),
  "component---src-pages-industrie-tools-made-js": () => import("./../../../src/pages/industrie/tools_made.js" /* webpackChunkName: "component---src-pages-industrie-tools-made-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-inquiry-successfull-js": () => import("./../../../src/pages/inquiry-successfull.js" /* webpackChunkName: "component---src-pages-inquiry-successfull-js" */),
  "component---src-pages-manufacturing-process-metal-process-bending-js": () => import("./../../../src/pages/manufacturing-process/metal-process/bending.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-bending-js" */),
  "component---src-pages-manufacturing-process-metal-process-casting-js": () => import("./../../../src/pages/manufacturing-process/metal-process/casting.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-casting-js" */),
  "component---src-pages-manufacturing-process-metal-process-cnc-manufacturing-js": () => import("./../../../src/pages/manufacturing-process/metal-process/cnc-manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-cnc-manufacturing-js" */),
  "component---src-pages-manufacturing-process-metal-process-die-casting-js": () => import("./../../../src/pages/manufacturing-process/metal-process/die-casting.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-die-casting-js" */),
  "component---src-pages-manufacturing-process-metal-process-js": () => import("./../../../src/pages/manufacturing-process/metal-process.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-js" */),
  "component---src-pages-manufacturing-process-metal-process-laser-cutting-js": () => import("./../../../src/pages/manufacturing-process/metal-process/laser-cutting.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-laser-cutting-js" */),
  "component---src-pages-manufacturing-process-metal-process-plasma-cutting-js": () => import("./../../../src/pages/manufacturing-process/metal-process/plasma-cutting.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-plasma-cutting-js" */),
  "component---src-pages-manufacturing-process-metal-process-punching-js": () => import("./../../../src/pages/manufacturing-process/metal-process/punching.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-punching-js" */),
  "component---src-pages-manufacturing-process-metal-process-waterjet-cutting-js": () => import("./../../../src/pages/manufacturing-process/metal-process/waterjet-cutting.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-waterjet-cutting-js" */),
  "component---src-pages-manufacturing-process-metal-process-welding-js": () => import("./../../../src/pages/manufacturing-process/metal-process/welding.js" /* webpackChunkName: "component---src-pages-manufacturing-process-metal-process-welding-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-consulting-js": () => import("./../../../src/pages/service/consulting.js" /* webpackChunkName: "component---src-pages-service-consulting-js" */),
  "component---src-pages-service-import-handling-js": () => import("./../../../src/pages/service/import_handling.js" /* webpackChunkName: "component---src-pages-service-import-handling-js" */),
  "component---src-pages-service-logistics-js": () => import("./../../../src/pages/service/logistics.js" /* webpackChunkName: "component---src-pages-service-logistics-js" */),
  "component---src-pages-service-quality-control-js": () => import("./../../../src/pages/service/quality_control.js" /* webpackChunkName: "component---src-pages-service-quality-control-js" */),
  "component---src-pages-service-sampling-js": () => import("./../../../src/pages/service/sampling.js" /* webpackChunkName: "component---src-pages-service-sampling-js" */),
  "component---src-pages-service-series-production-js": () => import("./../../../src/pages/service/series_production.js" /* webpackChunkName: "component---src-pages-service-series-production-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-supply-chain-dashboard-js": () => import("./../../../src/pages/supply_chain_dashboard.js" /* webpackChunkName: "component---src-pages-supply-chain-dashboard-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/careerDetails.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/newsCategory.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/newsDetails.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-news-overview-js": () => import("./../../../src/templates/newsOverview.js" /* webpackChunkName: "component---src-templates-news-overview-js" */)
}

